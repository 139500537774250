import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Button} from "semantic-ui-react";
import loadable from "@react-loadable/revised";
import LoaderComponent from "../LoaderComponent";
import userStore from "../adminComponents/userManagement/userStore";
import {useLocation} from "react-router-dom";
import {useServerData} from "../../state/serverDataContext";
import {getCMSItemById} from "../../helper/cmsHelper";
import {fetchMissingTextById} from "../../helper/util";

const TextStoreHTMLEditor = loadable({
    loader: () => import(/* webpackChunkName: 'TextStoreHTMLEditor' */ "./TextStoreHTMLEditor"),
    loading: () => LoaderComponent
});

/**
 *
 * @param props namespace, id, textStore
 * @textStore Fetch textStore from DB with loadTextsByNamespace await the result and set it as textStore prop.
 * @returns {JSX.Element}
 * @constructor
 */


const EditHTMLComponent = (props) => {
    //I don't know if this duplicate code can be removed
    const {id, i18n, namespace, style,className} = props;
    const [textStore, setTextStore] = useState(useServerData());
    const [textToShow, setTextToShow] = useState(getCMSItemById(textStore, id, i18n.language));
    const [modalOpen, setModalOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(userStore.isAdmin || userStore.isContentCreator);
    const search = useLocation().search;

    useEffect(() => {
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setTextToShow(text[id][i18n.language]);
            }
        });
    }, []);

    useEffect(() => {
        setCanEdit((userStore.isAdmin || userStore.isContentCreator) && search !== "?preview");
    }, [userStore.isAdmin, userStore.isContentCreator, search]);

    if (canEdit && typeof document !== "undefined") {
        return (
            <>
                <Button className={"circular edit_button admin-button-primary"}
                        color={'orange'}
                        icon={"edit outline"}
                        onClick={() => {
                            setModalOpen(true);
                        }}/>
                <TextStoreHTMLEditor id={id}
                                     modalOpen={modalOpen}
                                     textToShow={textToShow}
                                     namespace={namespace}
                                     onClose={(text) => {
                                         if (text !== "") {
                                             setTextToShow({
                                                 title: '',
                                                 description: '',
                                                 content: text,
                                                 isNamespace: namespace
                                             });
                                         }
                                         setModalOpen(false);
                                     }}
                />

                <div  className={canEdit ? 'admin_text_block' : ""}
                     id={id}
                     dangerouslySetInnerHTML={{__html: typeof textToShow !== "undefined" ? textToShow.content : null}}
                />
            </>
        )
    } else {
        return (
            <div style={style ? style : {}} className={className ? className : ''} id={id}
                 dangerouslySetInnerHTML={{__html: typeof textToShow !== "undefined" ? textToShow.content : null}}/>
        )
    }

}

export default withTranslation(['common'])(EditHTMLComponent)

