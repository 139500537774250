import React, {useEffect, useState} from "react"
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {Button} from 'semantic-ui-react/dist/commonjs'
import {fetchMissingTextById, getLanguage} from "../../helper/util";
import * as utils from "./EditLinkComponent/utils";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import DropdownItem from "../dropdownMenu/DropdownItem";
import config from "../../config/main.config"
import TranslatorModal from "../translatorModal/TranslatorModal";
import {useServerData} from "../../state/serverDataContext";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import routes from "../../../shared/routes"
import Autocomplete from "../Autocomplete";

const TextStoreLinkEditor = (props) => {

    const {i18n, id, modalOpen, onClose, namespace, textToShow} = props;
    const [language, setLanguage] = useState(getLanguage(i18n.language));
    const [targetLanguage, setTargetLanguage] = useState({});
    const [isEditState, setIsEditState] = useState(false);
    const [isTranslatorModalOpen, setIsTranslatorModalOpen] = useState(false);
    const [activeLanguageOptions, setActiveLanguageOptions] = useState([]);
    const [linkSuggestion, setLinkSuggestion] = useState([]);
    const [_textToShow, setTextToShow] = useState(textToShow);
    const [textStore, setTextStore] = useState(useServerData());

    useEffect(() => {
        utils.initializeTextStore(textStore,id,namespace,setTextStore,setActiveLanguageOptions,setTargetLanguage,i18n);
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setTextToShow(text[id][i18n.language]);
            }
        });
        const routesWithoutLang = routes.map(route => {
            if (route.path.includes(i18n.language)) {
                return route.path.replace(("/" + i18n.language + "/"), '')
            }
            return '';
        })
        //remove duplicates
        const uniqueRoutes = routesWithoutLang.filter(function (item, pos) {
            if (typeof item !== "undefined") {
                return routesWithoutLang.indexOf(item) === pos && !item.includes(":") && !item.includes("admin") && item.length > 0;
            }
            return '';
        })
        setLinkSuggestion(uniqueRoutes)
    }, [])

    useEffect(() => {
        utils.updateLanguage(textStore,id,language.languageCode,setTextToShow);
    }, [language]);

    return (
        <Modal
            open={modalOpen}
            className={'margin-auto'}>
            <Modal.Header
                className={"admin-primary"}>{i18n.t("common:common.edit")}</Modal.Header>
            <Modal.Content className={"admin-primary"} scrolling>
                {
                    isEditState ?
                        [
                            <div>
                                {
                                    activeLanguageOptions.length > 1 ?
                                        <Button className={"admin-button-basic"}
                                                style={{marginBottom: "10px"}}
                                                onClick={() => {
                                                    setIsTranslatorModalOpen(true);
                                                }}>
                                            {i18n.t("edit:translate.translator")}
                                        </Button>
                                        : null
                                }
                                <Form>
                                    <Form.Field>
                                        <label
                                            className={"admin-primary"}
                                            style={{marginTop: '10px'}}>
                                            <b>{i18n.t("edit:edit.link")}</b>
                                        </label>
                                        <div>
                                            <Autocomplete
                                                label={config.URL + i18n.language + '/'}
                                                suggestions={linkSuggestion}
                                                value={_textToShow.description}
                                                onChange={value => utils.changeText({target: {value: value}}, 'description', setTextToShow)}
                                            />
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <label className={"admin-primary"}>
                                            <b>{i18n.t("edit:edit.text")}</b>
                                        </label>
                                        <input
                                            className={"admin-primary"}
                                            placeholder={i18n.t("edit:edit.text") + " \nmax. 800 characters"}
                                            value={_textToShow.content}
                                            onChange={e => utils.changeText(e, 'content', setTextToShow)}/>
                                    </Form.Field>
                                </Form>
                                <Button.Group>
                                    <Button
                                        className={"admin-button-primary"}
                                        onClick={() => {
                                            utils.handleSave(_textToShow,
                                                namespace,
                                                textStore,
                                                id,
                                                language.languageCode,
                                                setIsEditState,
                                                setTextToShow,
                                                i18n,
                                                onClose);
                                        }}>
                                        {
                                            typeof textStore[id] === "undefined" || typeof textStore[id][language.languageCode] === "undefined" ?
                                                i18n.t("common:common.save")
                                                : i18n.t("common:common.edit")
                                        }
                                    </Button>
                                    <Button className={"admin-button-warn"}
                                            onClick={() => {
                                                setIsEditState(false);
                                            }}>
                                        {i18n.t("common:common.cancel")}
                                    </Button>
                                </Button.Group>
                            </div>,
                            <TranslatorModal isTranslatorOpen={isTranslatorModalOpen}
                                             setIsTranslatorOpen={setIsTranslatorModalOpen}
                                             setTargetLanguage={setTargetLanguage}
                                             targetLanguage={targetLanguage}
                                             language={language}
                                             textStore={textStore}
                                             activeLanguageOptions={activeLanguageOptions}
                                             id={id}
                                             editType={'link'}
                                             setIsEditState={setIsEditState}
                                             onClose={(text) => {
                                                 setTextToShow({...textStore, content: text.content, description: text.description});
                                             }}/>
                        ]
                        : <>
                            {
                                activeLanguageOptions.length > 1 ?
                                    <div style={{display: "inline-flex"}}>
                                        <label
                                            className={"admin-primary"}
                                            style={{
                                                margin: "auto",
                                                marginRight: "5px"
                                            }}>
                                            <b>{i18n.t("common:common.language")}: </b>
                                        </label>
                                        <p style={{margin: "auto", marginRight: "5px"}}>{language.language}</p>
                                        <DropdownMenu name={"Select Language"}
                                                      selector={true}
                                                      defaultValue={language.language}
                                                      onChange={(selection) => {
                                                          setLanguage(getLanguage(activeLanguageOptions.find(langToSet => langToSet.label === selection).value));
                                                      }}>
                                            {
                                                activeLanguageOptions.map((language, index) => (
                                                    <DropdownItem key={index}>{language.label}</DropdownItem>
                                                ))
                                            }
                                        </DropdownMenu>
                                        <Divider/>
                                    </div>
                                    : null
                            }
                            <label style={{display: "flex"}}><b>Name:</b></label>
                            <div id={id}
                                 dangerouslySetInnerHTML={{__html: typeof textToShow !== "undefined" ? textToShow.content : null}}/>
                            <label><b>Link:</b></label>
                            <p id={"ssr" + id}>{textToShow.description}</p>
                            <Button
                                className={'ui button circular admin-button-primary'}
                                icon="edit outline"
                                onClick={() => {
                                    setTextToShow(textToShow);
                                    setIsEditState(true);
                                }}/>
                            <Button
                                floated={"right"}
                                className={"admin-button-warn"}
                                onClick={() => onClose("")}>
                                {i18n.t("common:common.cancel")}
                            </Button>
                        </>
                }
            </Modal.Content>
        </Modal>
    )
}


TextStoreLinkEditor.propTypes = {
    id: PropTypes.string,
    modalOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

TextStoreLinkEditor.defaultProps = {
    id: Math.random().toString(),
    modalOpen: false,
    onClose: () => {
    }
};

export default withTranslation(['common', 'edit'])(TextStoreLinkEditor)
