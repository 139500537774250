import {getTranslatedText, textUpdate} from "../../helper/TextTranslatorUtil";
import {fetchTextByIdAndLanguage} from "../../helper/util";
import {initializeTextStore,updateLanguage} from "../editText/EditTextComponent/utils";

export {
    translateHeader,
    changeText,
    handleSave,
    initializeTextStore,
    updateLanguage
}

async function translateHeader(id, language, targetLanguage, textStore) {
    let translatedHeader = {content: '', description: '', title: ''};
    await getTranslatedText(language.languageCode,
        targetLanguage.languageCode,
        textStore[id][targetLanguage.languageCode].title)
        .then(translatedTitle => {
            translatedHeader.title = translatedTitle;
        });
    await getTranslatedText(language.languageCode,
        targetLanguage.languageCode,
        textStore[id][targetLanguage.languageCode].description)
        .then(translatedDescription => {
            translatedHeader.description = translatedDescription;
        });
    await getTranslatedText(language.languageCode,
        targetLanguage.languageCode,
        textStore[id][targetLanguage.languageCode].content)
        .then(translatedText => {
            translatedHeader.content = translatedText;
        });
    return translatedHeader;
}

function changeText(e, name, setText) {
    let value = e.target.value;
    switch (name){
        case 'title':
            if(value.length > 60)
                value = value.substr(0,60);
            break;
        case 'description':
            if(value.length > 160)
                value = value.substr(0,160);
            break;
        case 'content':
            if(value.length > 800)
                value = value.substr(0,800);
            break;
        default:
            break;
    }
    setText(prev => ({...prev, [name]: value}));
}

function handleSave(text, namespace, id, language, textStore, setIsEditState, setTextToShow, i18n, onClose) {
    let textToEdit = {
        content: text.content,
        title: text.title,
        description: text.description,
        isNamespace: namespace
    };
    setIsEditState(false);
    setTextToShow(textToEdit);
    if (language === i18n.language) {
        onClose(text);
    } else {
        onClose("");
    }
    textUpdate(textStore, textToEdit, id, language, namespace).then(bool => {
            fetchTextByIdAndLanguage(language, id).then(text => {
                textStore[id][language] = text[id][language];
            })
    });
}