import React, {useEffect, useState} from "react";
import {Menu} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";


const SocialIcons = () => {
    const [socialIcons,setSocialIcons] = useState([]);

    useEffect(()=>{
        fetch("/socialMedia/socialMedia.json").then(res=>{
            res.json().then(json =>{
                setSocialIcons(json.social_media)
            })
        })
    },[])

    return (
        <Menu icon={'labeled'}
              secondary
              borderless
              className={"text-secondary"}
              style={{border: "transparent", background: "transparent", display: "flex", flexDirection:"row", margin:'auto'}}>
            {socialIcons.map((icon, index) => (
                <Menu.Item
                    className={"social-icon"}
                    name={icon.name}
                    target={"_blank"}
                    href={icon.link}
                    style={{cursor:'pointer'}}
                    rel={"noreferrer"}
                    key={`menu.item.${index}`}
                >
                    <Icon className={"social-icon"} key={`menu.icon.${index}`} name={icon.image}/>
                    {icon.name}
                </Menu.Item>
            ))}
        </Menu>
    )
}

export default SocialIcons