import React from "react";
import {Dropdown, Menu} from "semantic-ui-react";

import config from "../config/main.config";
import languages from "../../shared/activeLanguages.json";
import activeLanguages from "../../shared/activeLanguages.json";

export const regexpMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexPhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;


export function getLogo(type) {
    return new Promise(resolve => fetch(config.BASE_URL + "logo/" + type, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'pragma': 'no-cache',
            'cache-control': 'no-cache'
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(json => {
                return resolve(json);
            })
        } else {
            // Error ?
        }
    }).catch(
        error => {
            console.error(error);
        }));
}

export function deleteFolder(name) {
    return new Promise(result => fetch(config.BASE_URL + "admin/images/userFolder/" + name, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({name: name})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            getFolders().then(folders => {
                return result(folders);
            })
        } else {
            alert("Error deleting folder");
        }
    }).catch(
        error => {
            console.error(error);
            /*if (error.status === 401) {
                history.replace("/admin-login");
            }*/
        }
    ));
}

export function createFolder(name) {
    return new Promise(result => fetch(config.BASE_URL + "admin/images/userFolder/" + name, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({name: name})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            getFolders().then(folders => {
                return result(folders);
            })
        } else {
            alert("Error creating folder");
        }
    }).catch(
        error => {
            console.error(error);
            /*if (error.status === 401) {
                history.replace("/admin-login");
            }*/
        }
    ));
}

export function getFolders() {
    return new Promise(result => fetch(config.BASE_URL + "admin/images/folders", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return result(json);
            });
        } else {
            console.error(response.status);
        }
    }).catch(() => {
        console.error("error");
    }));
}

export function fetchAllImages() {
    return new Promise(res => fetch(config.BASE_URL + "images/web", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                let allImages = [];
                for (let i = 0; i < json.length; i++) {
                    allImages.push({
                        name: json[i].substring(0, json[i].indexOf('.')),
                        link: json[i]
                    })
                }
                return res(allImages);
            });
        }
    }).catch(error => {
            console.error(error);
            if (error.status === 401) {
                window.history.replace("/admin-login");
            }
        }
    ));
}

export function getResponsiveImagePath(windowWidth) {

    let path = 'desktop/';
    if (windowWidth < 1372) {
        path = 'tablet/';
        if (windowWidth < 767) {
            path = 'mobile/';
        }
    }
    return path;
}

export function getLanguage(lang) {
    let allLanguages = languages.LANGUAGES;
    let languageToSet;
    for (let i = 0; i < allLanguages.length; i++) {
        if (allLanguages[i].languageCode === lang) {
            languageToSet = allLanguages[i]
            break;
        }
    }
    return languageToSet;
}

export function languageOptions(label, all = false) {
    let allLanguages = [];
    activeLanguages.LANGUAGES.map((language, index) => {
            if (language.isActive || all) {
                allLanguages.push({
                    index: index,
                    value: language.languageCode,
                    label: language.label
                })
            }
            return allLanguages;
        }
    )
    return allLanguages;
}

export function currentLang(i18n, label) {
    let currentLanguage = {};
    activeLanguages.LANGUAGES.map((language, index) => {
            if (language.languageCode === i18n.language) {
                currentLanguage = {
                    index: index,
                    value: language.languageCode,
                    label: language[label]
                }
            }
            return currentLanguage;
        }
    )
    return currentLanguage;
}

export function getAllLanguages(setLang) {
    return languages.LANGUAGES.map((languages, index) =>
        <Menu.Item
            className={"dropdown-item"}
            key={index}
            onClick={() => {
                setLang(languages);
            }}>
            {languages.language}
        </Menu.Item>
    )
}

export function getAllLanguageOptions(setLang) {
    return languages.LANGUAGES.map((languages, index) =>
        <Dropdown.Item
            text={languages.language}
            key={index}
            onClick={() => {
                setLang(languages);
            }}/>
    )
}

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}

export function getFromLocalStorage(type) {
    let object;
    if (typeof window !== "undefined") {
        if (type === "token")
            object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));
        if (type === "user")
            object = JSON.parse(localStorage.getItem(config.USER_STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            if (type === "token")
                return object.token;
            if (type === "user")
                return object.user;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
}

export function removeTokenFromStorage() {
    if (typeof window !== "undefined") {
        localStorage.removeItem(config.STORAGE_KEY);
        localStorage.removeItem(config.USER_STORAGE_KEY);
    }
}

export function setTokenLocalStorage(token) {
    if (typeof window !== "undefined") {
        let object = {
            "token": token,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}

export function setUserToLocalStorage(user) {
    if (typeof window !== "undefined") {
        let object = {
            "user": user,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.USER_STORAGE_KEY, JSON.stringify(object));
    }

}


export async function fetchMissingTextById(textStore, id, language) {
    if (typeof textStore[id] === "undefined") {
        return await fetchTextByIdAndLanguage(language, id);
    } else if (typeof textStore[id][language] !== "undefined") {
        let text = {};
        text[id] = textStore[id];
        return text;
    } else
        return {};
}

function addObjectToTexts(textObject) {
    let tempTexts = {};
    if (typeof tempTexts[textObject.frontendId] === "undefined") {
        tempTexts[textObject.frontendId] = {};
    }

    tempTexts[textObject.frontendId][textObject.language] = {
        content: textObject.content,
        title: textObject.title,
        description: textObject.description,
        isNamespace: textObject.namespace
    };
    return tempTexts;
}

export function fetchTextByIdAndLanguage(lang, frontendId) {
    //console.log("fetch by language: ", lang, "id: ",frontendId)
    return fetch(config.BASE_URL + "texts/" + lang + "/" + frontendId, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    }).then(response => {
        if (response.status >= 200 && response.status < 300 && response.status !== 204) {
            return response.json().then(json => {
                return addObjectToTexts(json);
            });
        }
    }).catch(
        error => {
            console.log(frontendId)
            console.error("Error on Fetch Text: ", error);
        }
    );
}

export function jumpToTop(window) {
    if (typeof window !== "undefined") {
        return window.scrollTo(0, 0);
    }
}

export function updateText(id, textToUpdate, language, ns) {
    return fetch(config.BASE_URL + "texts/", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({
            frontendId: id,
            content: textToUpdate.content,
            title: textToUpdate.title,
            description: textToUpdate.description,
            language: language,
            namespace: ns
        })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return true
        } else if (response.status === 401) {
            alert("Bitte melden Sie sich an");
        } else {
            alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
        }
    }).catch(
            error => {
                alert(error);
            }
        );
}

export function createText(id, textToCreate, language, ns) {
    return fetch(config.BASE_URL + "texts/", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({
            frontendId: id,
            content: textToCreate.content,
            title: textToCreate.title,
            description: textToCreate.description,
            language: language,
            namespace: ns
        })
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return true
            } else if (response.status === 401) {
                alert("Bitte melden Sie sich an");
            } else {
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
            }
        })
        .catch(
            error => {
                console.error(error);
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
            }
        );
}

async function backupUserData() {
    let result = await fetch(config.BASE_URL + "userData/backup", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    })
    if (result.status === 200)
        return 0;
    else
        return -1;
}

export function backup() {
    backupUserData().then((result) => {
        if (result !== 0)
            console.error("backup failed");
    })
}