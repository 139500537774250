import React from "react";
import { Icon } from "semantic-ui-react";
export default class ScrollUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_visible: false,
		};
	}

	componentDidMount() {
		let scrollComponent = this;
		document.addEventListener("scroll", function () {
			scrollComponent.toggleVisibility();
		});
	}

	toggleVisibility() {
		if (window.pageYOffset > 150) {
			this.setState({
				is_visible: true,
			});
		} else {
			this.setState({
				is_visible: false,
			});
		}
	}

	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}

	render() {
		return (
			<div className={"scroll_to_top"}>
				<div
					className={this.state.is_visible ? "scroll_show" : "scroll_hide"}
					onClick={() => this.scrollToTop()}
				>
					<Icon
						className={"scroll_up_button_image"}
						circular
						size={"big"}
						name={"chevron up"}
					/>
				</div>
			</div>
		);
	}
}
