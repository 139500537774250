import loadable from "@react-loadable/revised";
import LoaderComponent from "../src/components/LoaderComponent";
import languages from "./activeLanguages.json";
import dynamicRoutes from "./dynamicRoutes.json"
import index from "../src/userContent/userPages/index"

const Home = loadable({
    loader: () => import(/* webpackChunkName: 'Home' */"../src/pages/Home"),
    loading: () => LoaderComponent
});
const LoginPage = loadable({
    loader: () => import(/* webpackChunkName: 'Login' */"../src/pages/login/LoginPage"),
    loading: () => LoaderComponent
});
const RegisterPage = loadable({
    loader: () => import(/* webpackChunkName: 'Register' */"../src/pages/login/RegisterPage"),
    loading: () => LoaderComponent
});
const Imprint = loadable({
    loader: () => import(/* webpackChunkName: 'Imprint' */"../src/pages/Imprint"),
    loading: () => LoaderComponent
});
const DataPrivacy = loadable({
    loader: () => import(/* webpackChunkName: 'DataPrivacy' */"../src/pages/DataPrivacy"),
    loading: () => LoaderComponent
});
const NotFoundPage = loadable({
    loader: () => import(/* webpackChunkName: 'NotFound' */"../src/pages/not_found/NotFoundPage"),
    loading: () => LoaderComponent
});
const BlogArticle = loadable({
    loader: () => import(/* webpackChunkName: 'BlogArticle' */"../src/pages/blog/BlogArticle"),
    loading: () => LoaderComponent
});
const AGB = loadable({
    loader: () => import(/* webpackChunkName: 'AGB' */"../src/pages/TOS/TOSPage"),
    loading: () => LoaderComponent
});
const Blog = loadable({
    loader: () => import(/* webpackChunkName: 'Blog' */"../src/pages/blog/Blog"),
    loading: () => LoaderComponent
});
const Contact = loadable({
    loader: () => import(/* webpackChunkName: 'Contact' */"../src/pages/contact/Contact"),
    loading: () => LoaderComponent
});
const AboutPage = loadable({
    loader: () => import(/* webpackChunkName: 'AboutPage' */"../src/pages/about/About"),
    loading: () => LoaderComponent
});
const OfferPage = loadable({
    loader: () => import(/* webpackChunkName: 'OfferPage' */"../src/pages/OfferPage/OfferPage"),
    loading: () => LoaderComponent
});
const AdminPage = loadable({
    loader: () => import(/* webpackChunkName: 'AdminPage' */"../src/pages/adminPage"),
    loading: () => LoaderComponent
});

let components = []
for (let i = 0; i < dynamicRoutes.length; i++) {
    let component = index[i]
    components.push(component)
}
let routes = [];
for (let i = 0; i < languages.LANGUAGES.length; i++) {

    for (let c = 0; c < dynamicRoutes.length; c++) {
        let name = dynamicRoutes[c].name;
        let link = dynamicRoutes[c].link
        routes = routes.concat([
            {
                path: "/" + languages.LANGUAGES[i].languageCode + "/" + link,
                exact: true,
                component: components[c],
                namespace: name
            }])
    }

    routes = routes.concat([
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/admin",
            exact: true,
            component: AdminPage,
            namespace: "adminManagement"
        },
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/",
            exact: true,
            component: Home,
            namespace: "Home",
        },
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/home",
            exact: true,
            component: Home,
            namespace: "Home",
        },
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/offer",
            exact: true,
            component: OfferPage,
            namespace: "OfferPage",
        },
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/404",
            exact: true,
            component: NotFoundPage
        },
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/about",
            exact: true,
            namespace: 'about',
            component: AboutPage,
        },
        // Start: BlogRoutes
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/blog",
            exact: true,
            component: Blog,
            namespace: 'Blog',
            breadCrumbData: [{
                title: "titles.blog",
                url: "/blog"
            }],
        },
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/blog-article/:id",
            exact: true,
            component: BlogArticle,
            breadCrumbData: [{
                title: "titles.blog",
                url: "/blog"
            }],
        },
        // Start: User Management Routes
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/imprint",
            exact: true,
            component: Imprint,
            breadCrumbData: [{
                title: "titles.imprint",
                url: "/imprint"
            }],
            namespace: "imprint",
        }, {
            path: "/" + languages.LANGUAGES[i].languageCode + "/data-privacy",
            exact: true,
            component: DataPrivacy,
            breadCrumbData: [{
                title: "titles.data_privacy",
                url: "/data-privacy"
            }],
            namespace: "data_protection",
        }, {
            path: "/" + languages.LANGUAGES[i].languageCode + "/login",
            exact: true,
            component: LoginPage
        },/* {
            path: "/" + languages.LANGUAGES[i].languageCode + "/register",
            exact: true,
            component: RegisterPage
        },*/{
            path: "/" + languages.LANGUAGES[i].languageCode + "/agb",
            exact: true,
            component: AGB,
            breadCrumbData: [{
                title: "titles.agb",
                url: "/agb"
            }],
            namespace: 'agb',
        },
        //Kontakt
        {
            path: "/" + languages.LANGUAGES[i].languageCode + "/contact",
            extract: true,
            component: Contact,
            breadCrumbData: [{
                title: "titles.contact",
                url: "/contact"
            }],
        }
    ]);
}

export default routes;

