import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";

import config from "../config/main.config";

const Meta = (props) => {
	const { i18n, route } = props;
	const [hrefLangLinks, setHrefLangLinks] = useState([]);
	const [pathWithoutLang, setPathWithoutLang] = useState(route);
	const [htmlLang, setHtmlLang] = useState("de-DE");

	useEffect(() => {
		//eslint-disable-next-line react-hooks/exhaustive-deps
		if (typeof route !== "undefined") {
			//get current address
			setPathWithoutLang(route);

			setPathWithoutLang(pathWithoutLang.replace(`${i18n.language}/`, ""));

			setHrefLangLinks([
				<link
					key={"alternate-link-default"}
					rel="alternate"
					hrefLang="x-default"
					href={`${config.URL}de-DE/`}
				/>,
				<link
					key={"alternate-link-de"}
					rel="alternate"
					hrefLang="de-DE"
					href={`${config.URL}de-DE/`}
				/>,
				<link
					key={"alternate-link-en"}
					rel="alternate"
					hrefLang="en-EN"
					href={`${config.URL}en-EN/`}
				/>,
				<link
					key={"alternate-link-en"}
					rel="alternate"
					hrefLang="fr-FR"
					href={`${config.URL}fr-FR/`}
				/>,
			]);
		}
		if (i18n.language !== "de-DE") {
			setHtmlLang(i18n.language);
		}
	}, []);

	return <Helmet htmlAttributes={{ lang: htmlLang }}>{hrefLangLinks}</Helmet>;
};

export default withTranslation()(Meta);
