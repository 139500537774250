import i18n from "i18next";
import Backend from 'i18next-http-backend'
import {initReactI18next} from "react-i18next";
import {LanguageDetector} from "i18next-http-middleware";

let languageToSet = "de-DE";
if (typeof window !== "undefined") {
    languageToSet = window.location.pathname.substr(1, 5);
}

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({

        backend: {
            loadPath: '/locales/{{lng}}/locale.{{ns}}.json',
            addPath: '/locales/{{lng}}/locale.{{ns}}.json'
        },

        initImmediate: false,
        debug: false,
        lng: [`${languageToSet}`],
        load: 'currentOnly',
        preload: ['de-DE'],
        detection: {
            order: ['path' /*, 'header', 'session', 'querystring', 'cookie' */]
        },
        fallbackLng: false,
        ns: [],
        defaultNS: ['nav'],
        fallbackNS: ['nav'],
        interpolation: {
            escapeValue: false
        }
    }).then(()=>{});
export default i18n;



