import fetch from "node-fetch";
import config from "../../src/config/main.config";
import {getFromLocalStorage} from "../../src/helper/util";


export function loadTextsByNamespace(baseURL, namespace) {
    return fetch(baseURL + "texts/namespace/" + namespace, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json().then(json => {
                return json;
            });
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        console.error(error);
        return {
            error: "400"
        }
    });
}

export function getOneArticle(id, baseURL) {
    return new Promise(resolve => fetch(baseURL + "blog/article/" + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json().then(json => {
                return resolve(json);
            });
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        console.error(error);
        return {
            error: "400"
        }
    }));
}

export function getNewestArticles(baseURL, amount) {
    return new Promise(resolve => fetch(baseURL + "blog/articles/" + amount, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            })
        } else {
            return {error: "400"}
        }
    }).catch(
        error => {
            console.error(error);
            return {error: "400"}
        }
    ));

}


export function getAllArticles(page_, baseURL) {
    let page = 0;
    if (page_ !== 0) {
        page = page_;
    }

    return new Promise(resolve => fetch(baseURL + "blog/articles?page=" + page, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            })
        } else {
            return {error: "400"}
        }
    }).catch(
        error => {
            console.error(error);
            return {error: "400"}
        }
    ));
}

export function getFAQs(baseURL) {
    return fetch(baseURL + "faq/all", {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json().then(json => {
                return json;
            });
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        console.error(error);
        return {
            error: "400"
        }
    });
}

export async function isEntryInDB(id, namespace) {
    return fetch(config.BASE_URL + "texts/frontendId", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({data: {id: id, namespace: namespace}})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(text => {
                return text;
            })
        }
    }).catch(
        error => {
            console.error("Error on Fetch Text: ", error);
        }
    );
}



