import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Autocomplete = (props) => {
	const { suggestions, label, value, onChange: onChangeProps } = props;
	const [activeSuggestion, setActiveSuggestion] = useState(-1);
	const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [userInput, setUserInput] = useState(value);

	const onChange = (e) => {
		const userInput = e.currentTarget.value;

		// Filter our suggestions that don't contain the user's input
		const filteredSuggestions = suggestions.filter(
			(suggestion) =>
				suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
		);

		setActiveSuggestion(0);
		setFilteredSuggestions(filteredSuggestions);
		setShowSuggestions(true);
		setUserInput(e.currentTarget.value);
		onChangeProps(e.currentTarget.value);
	};

	const onClick = (e) => {
		setActiveSuggestion(0);
		setFilteredSuggestions([]);
		setShowSuggestions(false);
		setUserInput(e.currentTarget.innerText);
		onChangeProps(e.currentTarget.innerText);
	};

	const onKeyDown = (e) => {
		switch (e.keyCode) {
			case 13:
				// User pressed the enter key
				setActiveSuggestion(0);
				setShowSuggestions(false);
				setUserInput(filteredSuggestions[activeSuggestion]);
				onChangeProps(filteredSuggestions[activeSuggestion]);
				break;
			case 27:
				// User pressed escape
				setActiveSuggestion(0);
				setShowSuggestions(false);
				break;
			case 38:
				// User pressed the up arrow
				if (activeSuggestion === 0) {
					setActiveSuggestion(filteredSuggestions.length - 1);
					return;
				}
				setShowSuggestions(true);
				setActiveSuggestion(activeSuggestion - 1);
				break;
			case 40:
				// User pressed the down arrow
				if (activeSuggestion + 1 === filteredSuggestions.length) {
					setActiveSuggestion(0);
					return;
				}

				setShowSuggestions(true);
				setActiveSuggestion(activeSuggestion + 1);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<div className="ui labeled input">
				<div className="ui label label"> {label}</div>
				<input
					id={"suggestion-input"}
					type="text"
					onChange={onChange}
					onKeyDown={onKeyDown}
					value={userInput}
				/>
			</div>
			{showSuggestions && filteredSuggestions.length > 0 && (
				<ul className={"suggestions"}>
					{filteredSuggestions.map((suggestion, index) => {
						let className;

						// Flag the active suggestion with a class
						if (index === activeSuggestion) {
							className = "suggestionActive";
						}

						return (
							<li className={className} key={suggestion} onClick={onClick}>
								{suggestion}
							</li>
						);
					})}
				</ul>
			)}
		</>
	);
};

Autocomplete.propTypes = {
	suggestions: PropTypes.instanceOf(Array),
	label: PropTypes.instanceOf(String),
	value: PropTypes.instanceOf(String),
	onChange: PropTypes.instanceOf(Function),
};

Autocomplete.defaultProps = {
	suggestions: [],
	label: "",
	value: "",
	onChange: () => {},
};

export default withTranslation(["common", "edit"])(Autocomplete);
