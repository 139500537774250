import React, {useEffect, useRef, useState} from "react";
import userStore from "../userManagement/userStore";
import {NavLink, useLocation} from "react-router-dom";
import MenuComponent from "../../menu/Menu";
import {backup, removeTokenFromStorage} from "../../../helper/util";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {withTranslation} from "react-i18next";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import * as utils from "./utils";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {Dropdown} from "semantic-ui-react";
import SimplLogo from "../simplLogo";

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const AdminTopMenu = (props) => {
    const location = useLocation();
    const [adminMenu, setAdminMenu] = useState(false);
    const {t, i18n} = props;
    const [buildState, setBuildState] = useState('');
    const [rebuildNeeded, setRebuildNeeded] = useState(false);
    const [checkBuild, setCheckBuild] = useState(true);
    const countRef = useRef(buildState);
    countRef.current = buildState;
    useEffect(() => {
        setTimeout(() => {
            if (userStore.isAdmin && buildState !== 'success' && buildState !== 'failed') {
                utils.getBuildState().then(result => {
                    if (buildState !== result.state) {
                        setBuildState(result.state);
                    }
                })
                setCheckBuild(!checkBuild);
            }
        }, 10000);
    }, [checkBuild]);

    useEffect(() => {
        setAdminMenu(location.pathname.includes("admin"))
    }, [location])

    useEffect(() => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        if (userStore.isAdmin) {
            utils.getBuildState().then(result => {
                if (buildState !== result.state) {
                    setBuildState(result.state);
                }
            })
        }
    }, []);

    useCustomEventListener("rebuild", () => {
        setRebuildNeeded(true);
    })
    if (userStore.isAdmin || userStore.isContentCreator || userStore.isAuthor) {
        return (
            <Grid
                key={"top-menu-0"}
                className={"admin-secondary"} style={{
                position: 'fixed',
                width: '101%',
                zIndex: 3,
                top: 0
            }}>
                <Grid.Row
                    key={"top-menu-1"}
                    style={{marginTop: "14px"}}>
                    <Grid.Column
                        key={"top-menu-2"}
                        width={16}>
                        <MenuComponent
                            key={"top-menu-3"}
                            secondary
                            fluid>
                            <NavLink
                                key={"top-menu-4"}
                                style={{maxHeight: "2vh", marginLeft: "1rem", marginTop: "2px"}}
                                activeClassName={'nav_link_active'}
                                exact
                                to={`/${i18n.language}/admin`}>
                                <SimplLogo/>
                            </NavLink>
                            {
                                (userStore.isAdmin || userStore.isContentCreator) &&
                                <NavLink
                                    key={"top-menu-5"}
                                    className={"admin_edit_buttons link-admin"}
                                    activeClassName={'nav_link_active'}
                                    exact
                                    to={adminMenu ? `/${i18n.language}/?preview` : `?preview`}
                                    style={{marginRight: 20, marginLeft: 'auto'}}>
                                    <Icon name={'eye'}/>
                                    {t('titles.preview')}
                                </NavLink>
                            }
                            <NavLink
                                key={"top-menu-6"}
                                className={"admin_edit_buttons link-admin"}
                                activeClassName={'nav_link_active'}
                                exact
                                to={adminMenu ? `/${i18n.language}/` : `?adminview`}
                                style={userStore.isAuthor && !userStore.isAdmin && !userStore.isContentCreator ? {
                                    marginRight: 0,
                                    marginLeft: 'auto'
                                } : {marginRight: 0, marginLeft: '1rem'}}>

                                {
                                    userStore.isAuthor && !userStore.isAdmin && !userStore.isContentCreator ?
                                        adminMenu ?
                                            <>
                                                <Icon name={'arrow left'}/>
                                                {t('titles.back')}
                                            </>
                                            : null
                                        : <>
                                            <Icon name={'edit'}/>
                                            {t('titles.edit_page')}
                                        </>
                                }
                            </NavLink>
                            <label
                                key={"top-menu-7"}
                                className={"admin_edit_buttons link-admin"}
                                style={{marginRight: 0, marginLeft: '1rem'}}>
                                {
                                    buildState !== "success" && userStore.isAdmin ?
                                        buildState === 'installing' ?
                                            t("titles.installing")
                                            : buildState === "failed" ?
                                                t('titles.failed')
                                                : buildState === "failed: user-component" ?
                                                    t('titles.failed_component')
                                                    : t('titles.building')
                                        : rebuildNeeded ?
                                            t("titles.rebuild_needed")
                                            : null
                                }
                            </label>
                            {
                                userStore.isAdmin ?
                                    <Button
                                        disabled={true/*!rebuildNeeded || (buildState !== 'success' && buildState !== 'failed')*/}
                                        loading={buildState !== 'success' && buildState !== 'failed'}
                                        key={"top-menu-8"}
                                        onClick={() => {
                                            setBuildState("starting")
                                            setRebuildNeeded(false);
                                            setCheckBuild(!checkBuild);
                                            backup();
                                            utils.handleRebuild().then(() => {
                                            })
                                        }}
                                        compact
                                        className={"admin-button-primary"}
                                        style={{
                                            marginRight: "1rem",
                                            marginLeft: '1rem',
                                            marginTop: "auto",
                                            marginBottom: "auto"
                                        }}>
                                        {t('titles.rebuild')}
                                    </Button>
                                    : null
                            }
                            <Dropdown
                                key={"top-menu-9"}
                                style={{marginRight: "1rem", marginLeft: '1rem'}}
                                direction='left'
                                className={'admin_edit_buttons admin-secondary'}
                                icon={"user"}>
                                <Dropdown.Menu
                                    key={"top-menu-10"}
                                    style={{display: "none"}}
                                    className={"admin-secondary"}>
                                    <Dropdown.Item
                                        key={"top-menu-11"}
                                        className={"admin-menu-label"}
                                        content={
                                            <p
                                                key={"top-menu-12"}
                                                className={"admin_edit_buttons admin-menu-label"}>
                                                {
                                                    userStore.isAdmin ? "Role: Admin" : userStore.isContentCreator ? "Role: Content Creator" : userStore.isAuthor ? "Role: Author" : "Role: User"
                                                }
                                            </p>
                                        }
                                    />
                                    <Dropdown.Item
                                        key={"top-menu-13"}
                                        content={
                                            <NavLink
                                                key={"top-menu-14"}
                                                className={"admin_edit_buttons link-admin"}
                                                activeClassName={'nav_link_active'}
                                                onClick={() => {
                                                    removeTokenFromStorage();
                                                    userStore.user = null;
                                                }}
                                                exact
                                                to={`/${i18n.language}/`}>
                                                {t('titles.logout')}
                                            </NavLink>}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </MenuComponent>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    } else {
        return null;
    }
}

export default withTranslation('nav')(AdminTopMenu)