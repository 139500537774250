import React from "react";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";


const MenuComponent = (props) => {


    const {
        activeIndex,
        as,
        attached,
        borderless,
        children,
        className,
        color,
        compact,
        defaultActiveIndex,
        fixed,
        floated,
        fluid,
        icon,
        inverted,
        items,
        onItemClick,
        pagination,
        pointing,
        secondary,
        size,
        stackable,
        tabular,
        text,
        vertical,
        widths
    } = props;

    return (
        <Menu style={!vertical ? {display: "flex", flexFlow: "row wrap", flexDirection: "row", margin:'auto'} : {}}
              activeIndex={activeIndex}
              as={as}
              attached={attached}
              borderless={borderless}
              children={childrenToShow(children)}
              className={className}
              color={color}
              compact={compact}
              defaultActiveIndex={defaultActiveIndex}
              fixed={fixed}
              floated={floated}
              fluid={fluid}
              icon={icon}
              inverted={inverted}
              items={items}
              onItemClick={onItemClick}
              pagination={pagination}
              pointing={pointing}
              secondary={secondary}
              size={size}
              stackable={stackable}
              tabular={tabular}
              text={text}
              vertical={vertical}
              widths={widths}/>
    )
}

function childrenToShow(children) {
        return (
            <>
                {children}
            </>
        )
}

export default MenuComponent;