import React, {useEffect, useState} from "react"
import Navigation from "./components/navigation/Navigation"
import Footer from "./components/footer/Footer";
import Meta from "./components/Meta";
import {renderRoutes} from "react-router-config";
import allRoutes from "../shared/routes"
import {useHistory, useLocation} from "react-router-dom"
import CookieBanner from "./components/cookieBanner";
import ScrollUp from "./components/scrollUp";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import cookieBannerConfig from "../shared/cookieBanner.config.json"
import {useCookies} from "react-cookie";
import TagManager from 'react-gtm-module';
import i18n from "./i18n";
import AdminTopMenu from "./components/adminComponents/adminTopMenu/AdminTopMenu";
import userStore from "./components/adminComponents/userManagement/userStore";
import {backup} from "./helper/util";
import SideBar from "./components/sideBar/SideBar";

const App = () => {
    const location = useLocation();
    const history = useHistory();
    const [adminMenu, setAdminMenu] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [preview, setPreview] = useState(false);

    useEffect(() => {
        const gtm = async () => {
            if (cookies.consentGiven && cookies.consentGiven.includes('statistic')) {
                const gtmId = await import("../shared/gtm.config.json");
                if (gtmId.gtmId !== '') {
                    TagManager.initialize({gtmId: gtmId.gtmId});
                }
            }
        }
        gtm()


        if (userStore.isAdmin || userStore.isContentCreator || userStore.isAuthor) {
            backup();
        }
    }, [])

    useEffect(() => {
        if (!userStore.isAdmin) {
            if (cookies.adminState)
                removeCookie('adminState', {path: '/'});
        }
    })

    useEffect(() => {
        setAdminMenu(location.pathname.includes("admin"))
        if (adminMenu)
            setPreview(false);
        if (location.search === "?preview")
            setPreview(true);
        if (location.search === "?adminview")
            setPreview(false);
        if (preview && location.search !== "?preview" && location.search !== "?adminview" && !adminMenu) {
            history.push({search: "?preview"});
        }
    }, [location])

    //wtf is this?
    useEffect(() => {
        const unregisterHistoryListener = history.listen(location => {
            let currentPath = location.pathname;
            let currentLanguageQuery = location.pathname.slice(1, 6);
            let newPath = currentPath.replace(currentLanguageQuery, i18n.language);
            if (history.action === 'POP') {
                history.replace(newPath);
            }
        });
        return () => unregisterHistoryListener;

    }, [i18n.language]);

    return (
        <div className={'ui centered two column grid background-primary'}>
            <Meta route={location.pathname}/>
            {
                userStore.isAdmin || userStore.isContentCreator || userStore.isAuthor ?
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <AdminTopMenu/>
                        </Grid.Column>
                    </Grid.Row>
                    : null
            }
            {
                !adminMenu ?
                    <Grid.Row className={'padding-zero'}>
                        <Grid.Column width={16}>
                            <Navigation/>
                        </Grid.Column>
                    </Grid.Row>
                    : null
            }
            <div className={"centered one column row "} style={{paddingTop: '69px', background: '#fffcf6'}}>
                <div className={"sixteen wide column"} style={{minHeight: "70vh"}}>
                    <SideBar/>
                    {renderRoutes(allRoutes)}
                </div>
            </div>
            {
                !adminMenu ?
                    <div className={"row"}>
                        <div className={"sixteen wide column"}>
                            <Footer/>
                        </div>
                    </div>
                    : null
            }
            <ScrollUp/>
            <CookieBanner content={cookieBannerConfig.content}
                          header={cookieBannerConfig.header}
                          dshowMarketing={cookieBannerConfig.marketing}
                          showPerformance={cookieBannerConfig.performance}
                          showStatistic={cookieBannerConfig.statistic}/>
        </div>
    );
};

export default App
