export {
    setCookies
}

function setCookies(consent, performance, statistic, marketing, setCookie) {
    let content = '';
    if (consent) {
        if (performance) content += 'performance, ';
        if (statistic) content += 'statistic, ';
        if (marketing) content += 'marketing, ';

        content += 'required';
        setCookie('consentGiven', content, {path: '/', maxAge: 32000000});
    }
}