import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import LanguageSelection from "../../../components/navigation/LanguageSelection";
import MenuComponent from "../../../components/menu/Menu";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import config from "../../../config/main.config";
import EditLinkComponent from "../../../components/editLink/EditLinkComponent";
import { useWindowSize } from "../../../components/editImage/EditImageComponent/utils";

const HeaderNav = (props) => {
	const location = useLocation();
	const width = useWindowSize().width;
	const { i18n, t } = props;
	const [showNav, setShowNav] = useState(false);
	const [showSideMenu, setShowSideMenu] = useState(false);

	useEffect(
		(_) => {
			setShowSideMenu(false);
		},
		[location.pathname],
	);

	useEffect((_) => {
		if (typeof document !== "undefined") {
			document.addEventListener("keyup", (e) => {
				if (e.key === "Escape") {
					setShowSideMenu(false);
				}
			});
		}
	}, []);

	useEffect(
		(_) => {
			setTimeout((_) => {
				setShowNav(true);
			}, 10);
		},
		[showNav],
	);

	if (showNav) {
		return [
			<MenuComponent
				className={"navigation-top-bar"}
				vertical={false}
				fluid={true}
				secondary={true}
				stackable={false}
				size="huge"
				key={"header-menu"}
			>
				<Menu.Item
					style={{
						padding: 0,
						paddingLeft: "1rem",
						margin: 0,
					}}
				>
					<img
						src={
							showSideMenu
								? config.BASE_URL_IMAGES + "x_icon.svg"
								: config.BASE_URL_IMAGES + "burger_icon.svg"
						}
						alt={"Nav-Icons"}
						onClick={(_) => {
							setShowSideMenu(!showSideMenu);
						}}
						style={{ cursor: "pointer", width: "25px" }}
					/>
					<a
						className={"white-text"}
						onClick={(_) => {
							setShowSideMenu(!showSideMenu);
						}}
						style={{
							margin: "1rem",
							cursor: "pointer",
							color: "white",
						}}
					>
						{width <= 1110
							? ""
							: t(
									showSideMenu
										? "common:common.close"
										: "adminManagement:customComponent.menu",
								)}
					</a>
				</Menu.Item>
				{width > 1110 && (
					<MenuComponent
						className={"navigation-top-bar"}
						vertical={false}
						fluid={false}
						secondary={true}
						style={{ margin: "auto" }}
						stackable={false}
						size="huge"
					>
						<Menu.Item>
							<EditLinkComponent
								className={"white-text"}
								id={`Home-link-1`}
								namespace={"Home"}
							/>
						</Menu.Item>
						<Menu.Item>
							<NavLink
								className={"white-text"}
								to={"/" + i18n.language + "/about"}
							>
								{t("nav:titles.accommodation_prices").toUpperCase()}
							</NavLink>
						</Menu.Item>
						<Menu.Item>
							<NavLink
								className={"white-text"}
								to={"/" + i18n.language + "/blog"}
							>
								{t("nav:titles.blog").toUpperCase()}
							</NavLink>
						</Menu.Item>
						<Menu.Item>
							<NavLink
								className={"white-text"}
								to={"/" + i18n.language + "/contact"}
							>
								{t("nav:titles.contactAndBooking").toUpperCase()}
							</NavLink>
						</Menu.Item>
					</MenuComponent>
				)}
				<Menu.Menu style={width <= 1100 ? { marginLeft: "auto" } : {}}>
					<Menu.Item>
						<LanguageSelection as={"sequential"} />
					</Menu.Item>
				</Menu.Menu>
			</MenuComponent>,
			<div
				className={
					showSideMenu
						? "grid ui sidebar-container"
						: "grid ui sidebar-nav-hide"
				}
				key={"side-menu"}
			>
				<div className={"row centered navigation-menu"}>
					<div className={"sixteen wide column sidebar-section"}>
						<section>
							<NavLink to={"/" + i18n.language + "/"}>
								<h2>Château & {t("nav:titles.host")}</h2>
							</NavLink>
							<NavLink
								className={"sidebar-nav-item"}
								to={"/" + i18n.language + "/"}
							>
								{t("nav:titles.welcome")}
							</NavLink>
						</section>
						<section>
							<NavLink
								onClick={(_) => setShowSideMenu(false)}
								to={"/" + i18n.language + "/about"}
							>
								<h2>{t("nav:titles.accommodation")}</h2>
							</NavLink>
							<NavLink
								onClick={(_) => setShowSideMenu(false)}
								className={"sidebar-nav-item"}
								to={"/" + i18n.language + "/about#Romantique"}
							>
								Chambre Romantique
							</NavLink>
							<br />
							<NavLink
								onClick={(_) => setShowSideMenu(false)}
								className={"sidebar-nav-item"}
								to={"/" + i18n.language + "/about#LesDeuxLions"}
							>
								Les Deux Lions
							</NavLink>
							<br />
							<NavLink
								onClick={(_) => setShowSideMenu(false)}
								className={"sidebar-nav-item"}
								to={"/" + i18n.language + "/about#Bellevue"}
							>
								Bellevue
							</NavLink>
							<br />
							<NavLink
								onClick={(_) => setShowSideMenu(false)}
								className={"sidebar-nav-item"}
								to={"/" + i18n.language + "/about#LouisXIV"}
							>
								Ludwig XIV
							</NavLink>
							<br />
							<NavLink
								onClick={(_) => setShowSideMenu(false)}
								className={"sidebar-nav-item"}
								to={"/" + i18n.language + "/about#Napoléon"}
							>
								Napolèon
							</NavLink>
						</section>
						{/*<section>
                                <NavLink to={'/' + i18n.language + '/offer'}>
                                    <h2>{i18n.t('nav:titles.offer')}</h2></NavLink>
                            </section>*/}
						<section>
							<NavLink to={"/" + i18n.language + "/contact"}>
								<h2>{i18n.t("nav:titles.contactAndBooking")}</h2>
							</NavLink>
						</section>
					</div>
				</div>
			</div>,
		];
	}
	return <div />;
};

export default withTranslation(["common", "adminManagement", "nav"])(HeaderNav);
