import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import { preloadReady } from "@react-loadable/revised";
import App from "./App";
import history from "./helper/browserHistory";
import "./styles/_all.scss";
import { ServerDataProvider } from "./state/serverDataContext";
import ScrollToTop from "./helper/ScrollToTop";

let SERVER_DATA = {};

if (typeof window !== "undefined") {
	SERVER_DATA = window.__SERVER_DATA__;
}

export const Main = () => {
	return preloadReady()
		.then(() => {
			ReactDOM.hydrate(
				<Suspense
					fallback={
						<Button
							style={{ margin: "auto", display: "contents" }}
							content={"Loading..."}
							loading
							basic
							size={"massive"}
						/>
					}
				>
					<Router history={history}>
						<ScrollToTop />
						<ServerDataProvider value={SERVER_DATA}>
							<App />
						</ServerDataProvider>
					</Router>
				</Suspense>,
				document.getElementById("root"),
			);
		})
		.catch(console.error);
};

if (module.hot) {
	module.hot.accept();
}
