module.exports = {
    getCMSItemById,
    getDefaultCMSItem,
    getAllArticlesAtPage,
    getAllFAQItems,
    getArticleItem,
    getButtonItemById
}

const DEFAULT_CMS_ITEM = {
    title: '',
    description: '',
    content: '',
    isNamespace: ''
}

const DEFAULT_BUTTON_ITEM = {
    text: '',
    to: '',
    className: '',
    namespace: ''
}

const DEFAULT_BLOG_ITEM = []

const DEFAULT_FAQ_ITEM = []

const DEFAULT_ARTICLE_ITEM = {
    id: "",
    header: '',
    authorName: '',
    thumbnail: '',
    text: '',
    description: '',
    date: "",
    blogImage: '',
    seen: 0,
    blogLink: '',
    userId: ''
}

function getDefaultCMSItem() {
    return DEFAULT_CMS_ITEM;
}

/**
 * Get CMS Item by Id
 * @param textStore
 * @param id
 * @param language
 * @returns {{isNamespace: string, description: string, title: string, content: string}}
 */
function getCMSItemById(textStore, id, language = 'de-DE') {
    let item = DEFAULT_CMS_ITEM;

    if (textStore !== undefined && textStore[id] !== undefined) {
        item = textStore[id][language] || DEFAULT_CMS_ITEM;
    }
    return item;
}

function getButtonItemById(textStore, id, language = 'de-DE') {
    let item = DEFAULT_BUTTON_ITEM;
    if (textStore !== undefined && textStore[id] !== undefined) {
        item = textStore[id][language] || DEFAULT_CMS_ITEM;
    }
    return item;
}

function getAllArticlesAtPage(store) {
    let item = DEFAULT_BLOG_ITEM;

    if (store !== undefined && store.hasOwnProperty('articles')) {
        item = store.articles.articles;
    }

    return item;
}

function getArticleItem(store) {
    let item = DEFAULT_ARTICLE_ITEM;

    if (store !== undefined && store.hasOwnProperty('article')) {
        item = store.article;
    }

    return item;
}

function getAllFAQItems(store) {
    let item = DEFAULT_FAQ_ITEM;

    if (store !== undefined && store.hasOwnProperty('faqs')) {
        item = store.faqs;
    }
    return item;
}