import {Dimmer, Loader} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

const LoaderComponent = () => {
    return <Segment>
        <Dimmer active>
            <Loader/>
        </Dimmer>
    </Segment>;
}

export default (LoaderComponent);