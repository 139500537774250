import config from "../../config/main.config";
import {fetchTextByIdAndLanguage, getFromLocalStorage} from "../../helper/util";
import {getTranslatedText, textUpdate} from "../../helper/TextTranslatorUtil";
import {initializeTextStore,updateLanguage} from "../editText/EditTextComponent/utils";

export {
    checkForImage,
    handleSave,
    handleTranslate,
    initializeTextStore,
    updateLanguage
}

function checkForImage(selectedImagePreview, selectedImage, setSelectedImagePreview, setSelectedImage) {
    if (selectedImagePreview !== null && selectedImage !== null) {
        URL.revokeObjectURL(selectedImagePreview);
        setSelectedImagePreview(null);
        setSelectedImage(null);
    }
}

function handleSave(textToShow, selectedImage, selectedImagePreview, id, language, textStore, namespace, setIsEditState, setSelectedImage, setSelectedImagePreview, i18n, onClose) {
    let textToEdit = {
        content: textToShow.content,
        title: '',
        description: ''
    };
    if (selectedImage !== null && selectedImagePreview !== null) {
        let formData = new FormData();
        formData.append("image", selectedImage);
        fetch(config.BASE_URL + "texts/image/" + id, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getFromLocalStorage("token")
            },
            body: formData
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(() => {
                    textUpdate(textStore, textToEdit, id, language, namespace).then(bool => {
                        fetchTextByIdAndLanguage(language, id).then(text => {
                            textStore[id][language] = text[id][language];
                        })
                    });
                    setIsEditState(false);
                    checkForImage(selectedImagePreview, selectedImage, setSelectedImagePreview, setSelectedImage);
                });
            } else {
                checkForImage(selectedImagePreview, selectedImage, setSelectedImagePreview, setSelectedImage);
                //TODO: show Error ?
                //  this.error = "Error on fetching";
            }
        }).catch(
            () => {
                checkForImage(selectedImagePreview, selectedImage, setSelectedImagePreview, setSelectedImage)
                //TODO: show Error ?
                // this.error = "Error on fetching";
            });
    } else {
        textUpdate(textStore, textToEdit, id, language, namespace).then(bool => {
            fetchTextByIdAndLanguage(language, id).then(text => {
                textStore[id][language] = text[id][language];
            });
        });
        setIsEditState(false);
        checkForImage(selectedImagePreview, selectedImage, setSelectedImagePreview, setSelectedImage);
    }
    if (language === i18n.language) {
        onClose(textToShow.content);
    } else {
        onClose('');
    }
}

function handleTranslate(language, targetLanguage, textStore, id, textToShow, setTextToShow, setIsTranslatorOpen, setIsEditState) {
    try {
        getTranslatedText(language,
            targetLanguage,
            textStore[id][targetLanguage].content)
            .then(translatedText => {
                setTextToShow({...textToShow, content: translatedText});
                setIsTranslatorOpen(false);
                setIsEditState(false);
                setIsEditState(true);
            });
    } catch (e) {
        console.error(e);
        setIsTranslatorOpen(false);
        setIsEditState(false);
        setIsEditState(true);
    }
}