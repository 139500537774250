import React, {useEffect, useState} from "react";
import RichTextEditor, {getTextAlignClassName,
    getTextAlignStyles,
    getTextAlignBlockMetadata} from 'react-rte';
import {Button, Modal} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {withTranslation} from "react-i18next";

import {getResponsiveImagePath} from "../../helper/util";
import * as utils from "./EditTextComponent/utils";


const Editor = (props) => {
    const editor = React.createRef();
    const [imageModal, setImageModal] = useState(false);
    const [imageFilter, setImageFilter] = useState("");
    const [allImages, setAllImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [imagePath] = useState(getResponsiveImagePath(window.outerWidth));
    const [value, setValue] = useState(RichTextEditor.createValueFromString(props.text, props.format, {customBlockFn: getTextAlignBlockMetadata}));
    const {i18n} = props;

    useEffect(() => {
        if (selectedImage === 1) {
            setValue(RichTextEditor.createValueFromString(props.text, "html", {customBlockFn: getTextAlignBlockMetadata}));
        }
        setSelectedImage(0);
    }, [selectedImage]);

    useEffect(() => {
        utils.getAllImages(setAllImages);
    }, [])

    return (
        <RichTextEditor
            key={`editor-0`}
            autoFocus={true}
            ref={editor}
            value={value}
            onChange={(value_) => {
                props.updateText(value_.toString(props.format, {blockStyleFn: getTextAlignStyles}));
                setValue(value_);
            }}
            placeholder="Tell a story (max 65'000 characters)"
            toolbarConfig={RichTextEditor.toolbarConfig}
            blockStyleFn={getTextAlignClassName}
            customControls={[
                <Button
                    key={`editor-1`}
                    className={"admin-button-primary"}
                    icon={"image"}
                    style={{marginLeft: "5px"}}
                    size={"mini"}
                    onClick={() => {
                        utils.getAllImages(setAllImages);
                        setImageModal(true);
                    }}/>,
                <Modal
                    key={`editor-2`}
                    className={"admin-primary"}
                    open={imageModal}>
                    <Modal.Header
                        key={`editor-3`}
                        className={"admin-primary"}>Select Image</Modal.Header>
                    <Modal.Content
                        key={`editor-4`}
                        className={"admin-primary"} scrolling>
                        <label
                            key={`editor-5`}
                            className={"admin-primary"}>
                            <b key={`editor-6`}>{i18n.t("common:common.search")}</b>
                        </label>
                        <br/>
                        <input
                            key={`editor-7`}
                            className={"admin-primary"}
                            value={imageFilter}
                            onChange={(e) => {
                                setImageFilter(e.target.value);
                            }}/>
                        <Grid>
                            <Grid.Row key={`editor-8`}>
                                {utils.getFilteredImages(allImages,
                                    imageFilter, imagePath, value, editor, setImageModal, setSelectedImage)}
                            </Grid.Row>
                        </Grid>
                        <Modal.Actions key={`editor-9`}>
                            <Button
                                key={`editor-10`}
                                className={"admin-button-primary"}
                                icon={"checkmark"}
                                floated={"left"}
                                onClick={() => {
                                    setValue(RichTextEditor.createValueFromString(props.text, "html", {customBlockFn: getTextAlignBlockMetadata}));
                                    setImageModal(false);
                                }}/>
                            <Button
                                key={`editor-11`}
                                className={"admin-button-warn"}
                                floated={"right"}
                                onClick={() => setImageModal(false)}
                                icon={"delete"}/>
                        </Modal.Actions>
                    </Modal.Content>
                </Modal>
            ]}
        />
    )
}

export default withTranslation(['common'])(Editor)