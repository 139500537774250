import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import i18n from "i18next";
import { useWindowSize } from "../editImage/EditImageComponent/utils";

const SideBarItems = [
	{ icon: "call", to: "contact" },
	{ icon: "mail", to: "contact" },
	{ icon: "map", to: "contact" },
];

const SidebarMenu = () => {
	return SideBarItems.map((icon, index) => (
		<a
			className={"sidebar-anchor"}
			key={`sidebar_${index}`}
			href={
				index === 0
					? "tel:0033601234787"
					: index === 1
						? `mailto:info@chateauleval.fr?subject=Anfrage&body=${i18n.t(
								"component:descriptions.mail",
								{ room: "_____" },
							)}`
						: `/${i18n.language}/${icon.to}`
			}
		>
			<div
				className={"sidebar-icon-container"}
				style={{ display: "flex", justifyContent: "center" }}
			>
				<Icon
					className={"icon-hover"}
					style={{ padding: "15px" }}
					name={icon.icon}
					inverted
					fitted
					size={"large"}
				/>
			</div>
		</a>
	));
};

const SideBar = () => {
	const isMobile = useWindowSize().width <= 600;

	return (
		<div
			className={isMobile ? "side-bar-container-mobile" : "side-bar-container"}
		>
			{SidebarMenu()}
		</div>
	);
};

export default withTranslation(["component"])(SideBar);
