import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import * as utils from "./utils";

const CookieBanner = (props) => {
	const { showMarketing, showStatistic, showPerformance, i18n } = props;
	const [cookies, setCookie] = useCookies();
	//cookies that require consent
	const [marketing, setMarketing] = useState(
		cookies.consentGiven?.includes("marketing") ?? false,
	);
	const [statistic, setStatistic] = useState(
		cookies.consentGiven?.includes("statistic") ?? false,
	);
	const [performance, setPerformance] = useState(
		cookies.consentGiven?.includes("performance") ?? false,
	);
	//set if user confirmed cookie preferences
	const [consent, setConsent] = useState(
		cookies.consentGiven?.includes("required") ?? false,
	);

	useEffect(() => {
		if (cookies.consentGiven) {
			setConsent(cookies.consentGiven.includes("required"));
		} else {
			setConsent(false);
		}
	}, [cookies]);

	useEffect(() => {
		utils.setCookies(
			consent,
			performance,
			statistic,
			marketing,
			setCookie,
			cookies,
		);
	}, [consent]);

	if (consent || (!showPerformance && !showPerformance && !showStatistic)) {
		return null;
	}
	return (
		<div className={"cookie-banner-container"}>
			<div className={"ui grid background-secondary text-secondary"}>
				<div className={"cookie-banner-content"}>
					<div className={"row centered"}>
						<div
							className={
								"twelve wide computer fourteen wide tablet fourteen wide mobile column"
							}
						>
							<h1 className={"header-secondary"}>
								{i18n.t("cookieBanner:cookie_banner.header")}
							</h1>
							<p>
								{i18n.t("cookieBanner:cookie_banner.text")}
								<Link to={`/${i18n.language}/data-privacy`}>
									{" "}
									{i18n.t("cookieBanner:cookie_banner.data_privacy")}{" "}
								</Link>
							</p>
							<div className={"cookie-banner-checkbox-container"}>
								<div className={"checkbox"}>
									<input
										name={"consent"}
										id={"consent"}
										type={"checkbox"}
										defaultChecked={true}
										disabled={true}
									/>
									<label
										htmlFor={"consent"}
										className={"background-secondary text-secondary"}
									>
										{i18n.t("cookieBanner:cookie_banner.essential")}
									</label>
								</div>
								{showPerformance ? (
									<div className={"checkbox"}>
										<input
											name={"performance"}
											id={"performance"}
											type={"checkbox"}
											defaultChecked={false}
											onChange={(e) => setPerformance(e.target.checked)}
										/>
										<label
											htmlFor={"performance"}
											className={"background-secondary text-secondary"}
										>
											{i18n.t("cookieBanner:cookie_banner.performance")}
										</label>
									</div>
								) : null}
								{showStatistic ? (
									<div className={"checkbox"}>
										<input
											name={"statistic"}
											id={"statistic"}
											type={"checkbox"}
											defaultChecked={false}
											onChange={(e) => setStatistic(e.target.checked)}
										/>
										<label
											htmlFor={"statistic"}
											className={"background-secondary text-secondary"}
										>
											{i18n.t("cookieBanner:cookie_banner.statistic")}
										</label>
									</div>
								) : null}
								{showMarketing ? (
									<div className={"checkbox"}>
										<input
											name={"marketing"}
											id={"marketing"}
											type={"checkbox"}
											defaultChecked={false}
											onChange={(e) => setMarketing(e.target.checked)}
										/>
										<label
											className={"background-secondary text-secondary"}
											htmlFor={"marketing"}
										>
											{i18n.t("cookieBanner:cookie_banner.marketing")}
										</label>
									</div>
								) : null}
							</div>
							<div style={{ paddingBottom: "1rem" }}>
								<Button
									className={"button-secondary"}
									onClick={() => {
										setConsent(true);
									}}
								>
									{i18n.t("cookieBanner:cookie_banner.save_preferences")}
								</Button>
								<Button
									className={"button-secondary"}
									onClick={() => {
										setStatistic(false);
										setMarketing(false);
										setPerformance(false);
										setConsent(true);
									}}
								>
									{i18n.t("cookieBanner:cookie_banner.accept_essential")}
								</Button>
								<Button
									className={"button-primary"}
									onClick={() => {
										setStatistic(true);
										setMarketing(true);
										setPerformance(true);
										setConsent(true);
									}}
								>
									{i18n.t("cookieBanner:cookie_banner.accept_all")}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation(["cookieBanner"])(CookieBanner);
