import config from "../../../config/main.config";
import {getFromLocalStorage} from "../../../helper/util";

export {
    getBuildState,
    handleRebuild,
    handleReload
}

async function getBuildState() {
    return new Promise(resolve => fetch(config.BASE_URL + "build/", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },

    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(state => {
                return resolve(state)
            })
        }
    }).catch(
        error => {
            console.error("Error on Get Build state: ", error);
        }))
}

async function handleRebuild() {
    let result;
    result = await fetch(config.BASE_URL + "build/rebuild", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
    })
    result = result.status;
    return result;
}

async function handleReload() {
    let result;
    result = await fetch(config.BASE_URL + "build/reload", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
    })
    result = result.status;
    return result;
}