import React from 'react';
import {withTranslation} from "react-i18next";
import {loadTextsByNamespace} from "../../../../server/helper/ssrHelper";
import config from "../../../config/main.config";
import HeaderNav from '../../../userContent/userMenus/HeaderNavMenu/HeaderNavMenu';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import userStore from "../../adminComponents/userManagement/userStore";

const namespace = "Navigation";
const Navigation = () => {
    return (
        <Grid className='background-secondary text-secondary'
              style={userStore.isAdmin || userStore.isContentCreator || userStore.isAuthor ? {
                  position: 'fixed',
                  width: '100vw',
                  zIndex: 2,
                  top: '73px',
                  margin: 'auto'
              } : {
                  position: 'fixed',
                  width: '100vw',
                  zIndex: 2,
                  top: 0,
                  left: 0,
                  margin: 'auto'
              }}>
            <Grid.Row className={'mobile-nav-bottom-padding'}>
                <Grid.Column width={16} className={'padding-zero'}>
                    <HeaderNav namespace={namespace}>
                    </HeaderNav>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
Navigation.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'Triple-A-SoftHeader').then((text) => {
        return text;
    })
}
export default withTranslation(['nav'])(Navigation);
