import React from "react"

const DropdownItem = ({children, href, target,value}) => {
    if(href){
        return (
            <a
                href={href}
                target={target}>
                {children}
            </a>
        )
    }else{
        return (
            <div>
                {children}
            </div>
        )
    }

}

export default DropdownItem