import React from "react";
import config from "../../../config/main.config";
import * as packageInfo from "../../../../package.json";
const SimplLogo = () => {
	return (
		<div key={`logo-0`} className={"link-admin"} style={{ display: "flex" }}>
			<img
				key={`logo-1`}
				alt={"SIMPL CMS Logo"}
				className={"simpl-logo"}
				src={config.BASE_URL_IMAGES + "simpl_rocket.png"}
			/>
			<div key={`logo-2`}>
				<b key={`logo-3`}>SIMPL CMS</b> <br key={`logo-4`} />
				<p key={`logo-5`}>{"version: " + packageInfo.version}</p>
			</div>
		</div>
	);
};

export default SimplLogo;
