import {getTranslatedText, textUpdate} from "../../../helper/TextTranslatorUtil";
import {fetchTextByIdAndLanguage} from "../../../helper/util";
import {initializeTextStore, updateLanguage} from "../../editText/EditTextComponent/utils";

export {
    changeText,
    translateLink,
    handleSave,
    initializeTextStore,
    updateLanguage
}

function changeText(e, name, setText) {
    let value = e.target.value;
    let regex = /%?:?\/?\??#?\[?]?@?!?\$?&?'?\(?\)?\*?\+?,?;?=?\.?\u0020?/g;
    switch (name){
        case 'description':
            if(value.length > 60)
                value = value.substr(0,60);
            value = value.replace(regex, '');
            value = value.charAt(0).toLowerCase() + value.slice(1);
            break;
        case 'content':
            if(value.length > 800)
                value = value.substr(0,800);
            break;
        default:
            break;
    }
    setText(prev => ({...prev, [name]: value}));
}

async function translateLink(id, language, targetLanguage, textStore) {
    let translatedLink = {content: '', description: textStore[id][targetLanguage.languageCode].description};
    await getTranslatedText(language.languageCode,
        targetLanguage.languageCode,
        textStore[id][targetLanguage.languageCode].content)
        .then(translatedText => {
            translatedLink.content = translatedText;
        });
   return translatedLink;
}

function handleSave(text, namespace, textStore, id, language, setIsEditState, setTextToShow, i18n, onClose) {
    let textToEdit = {
        description: text.description,
        content: text.content,
        isNamespace: namespace
    };
    textUpdate(textStore, textToEdit, id, language, namespace).then(bool => {
        fetchTextByIdAndLanguage(language, id).then(text => {
            textStore[id][language] = text[id][language];
        });
    });
    setIsEditState(false);
    setTextToShow(textToEdit);
    if (language === i18n.language) {
        onClose(textToEdit);
    } else {
        onClose('');
    }
}