import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {withTranslation} from "react-i18next";
import loadable from "@react-loadable/revised";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Label} from "semantic-ui-react";

import LoaderComponent from "../../LoaderComponent";
import {fetchMissingTextById, languageOptions} from "../../../helper/util";
import userStore from "../../adminComponents/userManagement/userStore";
import {getCMSItemById} from "../../../helper/cmsHelper";
import {useServerData} from "../../../state/serverDataContext";


const TextStoreLinkEditor = loadable({
    loader: () => import(/* webpackChunkName: 'TextStoreLinkEditor' */ "../TextStoreLinkEditor"),
    loading: () => LoaderComponent
});

const EditLinkComponent = (props) => {
    //I don't know if this duplicate code can be removed
    const {id, i18n, namespace, primary, secondary, style, className} = props;
    const [textStore, setTextStore] = useState(useServerData());
    const [textToShow, setTextToShow] = useState(getCMSItemById(textStore, id, i18n.language));
    const [modalOpen, setModalOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(userStore.isAdmin || userStore.isContentCreator);
    const search = useLocation().search;

    useEffect(() => {
        fetchMissingTextById(textStore, id, i18n.language).then(text => {
            let newTextStore = {
                ...textStore,
                ...text
            }
            if (text) {
                setTextStore(newTextStore);
                setTextToShow(text[id][i18n.language]);
            }
        });
    }, []);

    useEffect(() => {
        setCanEdit((userStore.isAdmin || userStore.isContentCreator) && search !== "?preview");
    }, [userStore.isAdmin, userStore.isContentCreator, search]);

    if (canEdit && typeof document !== "undefined") {
        return (
            <>
                <Button className={"circular edit_button admin-button-primary"}
                        style={{top: '-10px'}}
                        icon={"edit outline"}
                        onClick={() => {
                            setModalOpen(true);
                        }}/>
                <TextStoreLinkEditor id={id}
                                     modalOpen={modalOpen}
                                     namespace={namespace}
                                     textToShow={textToShow}
                                     onClose={(text) => {
                                         if (text !== "") {
                                             setTextToShow({
                                                 title: '',
                                                 description: text.description,
                                                 content: text.content,
                                                 isNamespace: namespace
                                             });
                                         }
                                         setModalOpen(false);
                                     }}
                />
                <div>
                    <nav className={className ? className : ''} style={style ? style : {}}>
                        <NavLink  style={style ? style : {}}
                                  className={primary ? "link-primary" : secondary ? "link-secondary" : ""}
                                 to={`/${i18n.language}/${textToShow.description}`}>{textToShow.content}</NavLink>
                    </nav>
                    <div>
                        {textToShow.isNamespace === namespace ? "" :
                            <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                                   color={"red"}>!</Label>}
                    </div>
                </div>
            </>
        )
    } else {

        return (
            <>
                <nav className={className ? className : ''} style={style ? style : {}}>
                    <NavLink style={style ? style : {}}
                             className={primary ? "link-primary" : secondary ? "link-secondary" : "" }
                             to={`/${i18n.language}/${textToShow.description}`}>{textToShow.content}</NavLink>
                </nav>
            </>)
    }
}

export default withTranslation(['common'])(EditLinkComponent)
