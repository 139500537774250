import React, { useEffect, useState } from "react";
import DropdownMenu from "../dropdownMenu/DropdownMenu/DropdownMenu";

import DropdownItem from "../dropdownMenu/DropdownItem/DropdownItem";
import history from "../../helper/browserHistory";
import { currentLang, languageOptions } from "../../helper/util";
import List from "semantic-ui-react/dist/commonjs/elements/List";
import { withTranslation } from "react-i18next";

function changeCurrentLanguage(language, i18n) {
	i18n.changeLanguage(language).then(() => {
		const currentPath = window.location.pathname;
		const currentLanguageQuery = window.location.pathname.slice(1, 6);
		const newPath = currentPath.replace(currentLanguageQuery, i18n.language);
		history.replace(newPath);
	});
}

const MOBILE = 425;

const LanguageSelection = (props) => {
	const {
		as = "dropdownMenu",
		horizontal,
		valueToShow = "nativeLanguage",
		i18n,
		className,
	} = props;
	const [languages, setLanguages] = useState([]);
	const [windowWidth, setWindowWidth] = useState(1920);

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		setWindowWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
	}, []);
	useEffect(() => {
		setLanguages(languageOptions(valueToShow));
	}, []);

	switch (as) {
		case "sequential":
			return languages.map((language, index) => (
				<div
					key={"language " + index}
					className={
						(index < languages.length - 1 && className ? className : "") +
						(i18n.language === language.value
							? "active-language"
							: "language-selection-item")
					}
					onClick={(_) => changeCurrentLanguage(language.value, i18n)}
				>
					{language.label}
				</div>
			));
		case "dropdownMenu":
			return (
				<DropdownMenu
					name={"Select Language"}
					selector
					defaultValue={currentLang(i18n, valueToShow).label}
					onChange={(language) => {
						const langaueToSet = languages.find((x) => x.label === language);
						changeCurrentLanguage(langaueToSet.value, i18n);
					}}
				>
					{languages.map((language, index) => (
						<DropdownItem key={index}>{language.label}</DropdownItem>
					))}
				</DropdownMenu>
			);
		case "list":
			return (
				<List selection horizontal={horizontal && windowWidth > MOBILE}>
					{languages.map((language, index) => (
						<List.Item
							key={index}
							onClick={() => {
								changeCurrentLanguage(language.value, i18n);
							}}
						>
							<List.Content>{language.label}</List.Content>
						</List.Item>
					))}
				</List>
			);
		default:
			return (
				<ul>
					{languages.map((language, index) => (
						<li key={index}>{language.label}</li>
					))}
				</ul>
			);
	}
};

export default withTranslation()(LanguageSelection);
