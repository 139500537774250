import React from 'react';
import {withTranslation} from "react-i18next";
import {loadTextsByNamespace} from "../../../../server/helper/ssrHelper";
import config from "../../../config/main.config";
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import EditHTMLComponent from '../../../components/editHTML';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';
import SocialIcons from '../../../components/socialIcons';
import MenuComponent from "../../menu/Menu";
import LanguageSelection from "../../navigation/LanguageSelection";
import {NavLink} from "react-router-dom";
import {useWindowSize} from "../../editImage/EditImageComponent/utils";

const namespace = "footer";
const Footer = (props) => {
    let width = useWindowSize().width;

    const {i18n, t} = props;
    return (
        <Grid className='background-secondary text-secondary' style={{paddingTop: "4rem"}} stackable={true}>
            <div className={'row centered'}>
                <div className={'column'} style={{display: 'flex'}}>
                    <img src={config.BASE_URL_IMAGES + 'logo_white.png'} alt={'LOGO'}
                         style={{margin: 'auto', maxWidth: '100px'}}/>
                </div>
            </div>
            <Grid.Row centered>
                <Grid.Column width='8' textAlign='center' className={'footer-text'}>
                    <EditHTMLComponent namespace={namespace}
                                       id={`${namespace}-footer-text-0`}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <div className={'eight wide computer fourteen wide mobile column'} style={{display: 'flex'}}>
                    <SocialIcons/>
                </div>
            </Grid.Row>
            <div className={'row centered'}>
                <div className={'twelve wide mobile column'} style={{display: 'contents'}}>
                    <MenuComponent className={'footer-menu'} vertical={false} secondary={true} size='tiny'>
                        <Menu.Item className={'footer-menu-item'}>
                            <NavLink className={"white-text"} to={`/${i18n.language}/contact`}>{t('nav:titles.arrival')}</NavLink>
                        </Menu.Item>
                        <Menu.Item className={'footer-language-selection'}>
                            <LanguageSelection as={'sequential'}/>
                        </Menu.Item>
                    </MenuComponent>
                </div>
            </div>
            <div className={'row centered'} style={width <= 600 ? {
                backgroundColor: '#FAFAFA',
                padding: 0,
                paddingBottom: '4rem'
            } : {backgroundColor: '#FAFAFA', padding: 0,}}>
                <div className={'column'} style={{color: "black", display: 'contents'}}>
                    <MenuComponent className={"footer-bottom-navigation"} vertical={width <= 600} secondary={true}
                                   stackable={true}>
                        <Menu.Item>
                            <NavLink to={`/${i18n.language}/agb`}>{t('nav:titles.agb')}</NavLink>
                        </Menu.Item>
                        <Menu.Item>
                            <NavLink to={`/${i18n.language}/imprint`}>{t('nav:titles.imprint')}</NavLink>
                        </Menu.Item>
                        <Menu.Item>
                            <NavLink to={`/${i18n.language}/data-privacy`}>{t('nav:titles.data_privacy')}</NavLink>
                        </Menu.Item>
                        <Menu.Item>
                            <NavLink to={'/' + i18n.language + '/login'}>LOGIN</NavLink>
                        </Menu.Item>
                        <Menu.Item>
                            ©{new Date().getFullYear()} Château le Val Brix
                        </Menu.Item>
                    </MenuComponent>
                </div>
            </div>
        </Grid>
    )
}
Footer.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'footer').then((text) => {
        return text;
    })
}
export default withTranslation(['nav'])(Footer);
